// Ensure that the router preserves the store query params when navigating between pages.
import Router, { NextRouter } from "next/router";
import { isServer } from "@eleo/core";
import { getStoreFromLocalStorage } from "@eleo/suite/contexts/auth.context";

export const wrappedRouterPush = (
  router: NextRouter,
  destination: string,
  queryParams?: object,
  as?: string,
  options?: object,
) => {
  const activeStore = router.query.activeStore;

  router.push(
    {
      pathname: `/${activeStore}${destination}`,
      query: {
        ...(queryParams || {}),
      },
    },
    as,
    options,
  );
};

export const injectStoreIntoLink = (href: string) => {
  if (isServer()) return href;

  const activeStore = Router.query.activeStore;

  if (activeStore) {
    return `/${activeStore}${href}`;
  }

  // Check for activeStore in local storage
  const store = getStoreFromLocalStorage();
  if (store && store.id) {
    return `/${store.id}${href}`;
  }

  return href;
};
