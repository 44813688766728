import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { globalStyles } from "../../styles/global";
import { NavigationProps } from "../Navigation/Navigation";
import { darkTheme, styled } from "../../styles";
import { BaseNavigationItem, NavigationItem } from "../Navigation/NavItem";
import { NavigationGroup } from "../Navigation/NavGroup";
import { ModalProvider } from "../Modal/Modal";
import { MobileNav } from "../Navigation/MobileNav";
import dynamic from "next/dynamic";
import { OjaUI, ThemeOptions } from "./provider-utils";

// TODO: Temporary; Harmonise with `apps/shop/config/constants.ts`
export const storageKeys = {
  themePreferenceKey: "X_ELEO_THEME",
};

const Navigation = dynamic(() => import("../Navigation/Navigation"), {
  ssr: false,
});
const TopNavigation = dynamic(() => import("../Navigation/TopNavigation"), {
  ssr: false,
});

export interface OjaUIProviderProps {
  navProps: NavigationProps;
  layoutOptions?: LayoutOptions;
  currentPagePath: string;
}

export interface LayoutOptions {
  hideSideMenu?: boolean;
  hideTopNav?: boolean;
  hideFloatingActions?: boolean;

  //   Container Styling
  mainContainerStyles?: object;
}

const LayoutContainer = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr",

  "@bp1a": {
    gridTemplateColumns: "256px 1fr",
  },

  "@print": {
    gridTemplateColumns: "1fr",
  },

  variants: {
    singleColumn: {
      true: {
        gridTemplateColumns: "1fr",
      },
    },
  },
});

const Main = styled("main", {
  flex: 1,
  overflowY: "auto",
  padding: "0 $3 160px",
  position: "relative",

  "@bp1a": {
    height: "100vh",
  },
});

const getCurrentPageSubnavItems = (
  navGroups: NavigationGroup[],
  currentPagePath: string,
): BaseNavigationItem[] => {
  let currentItem: NavigationItem | undefined;

  for (const group of navGroups) {
    currentItem = group.items.find((item) =>
      currentPagePath.startsWith(item.href),
    );
    if (currentItem) {
      break;
    }

    /* eslint-disable */
        currentItem = group.items.find(
            (item) =>
                item.subItems?.find((subItem) =>
                    currentPagePath.startsWith(subItem.href),
                ),
        );
        /* eslint-enable */
    if (currentItem) {
      break;
    }
  }

  return currentItem?.subItems || [];
};

const detectSystemTheme = (): ThemeOptions => {
  if (typeof window === "undefined") {
    return ThemeOptions.light;
  }

  const storedPreference = localStorage.getItem(storageKeys.themePreferenceKey);

  if (
    storedPreference &&
    [ThemeOptions.light, ThemeOptions.dark].includes(
      storedPreference as ThemeOptions,
    )
  ) {
    return storedPreference as ThemeOptions;
  }

  const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
  return isDarkMode ? ThemeOptions.dark : ThemeOptions.light;
};

export const useLayout = () => useContext(OjaUI);

export const OjaUIProvider = ({
  children,
  navProps,
  layoutOptions,
  currentPagePath,
}: PropsWithChildren<OjaUIProviderProps>) => {
  // Ignore the system theme for now and default to light
  const defaultTheme = ThemeOptions.light || detectSystemTheme();

  const [theme, setTheme] = React.useState<ThemeOptions>(defaultTheme);
  globalStyles();

  //Only used on mobile - this controls the side menu state
  const [showMobileSideMenu, setShowMobileSideMenu] = useState<boolean>(false);

  const currentSubNavItems = useMemo(
    () => getCurrentPageSubnavItems(navProps.navGroups, currentPagePath),
    [currentPagePath, navProps.navGroups],
  );

  const themeClassName = theme === ThemeOptions.dark ? darkTheme : "";

  // Persist the theme preference
  useEffect(() => {
    localStorage.setItem(storageKeys.themePreferenceKey, theme);
  }, [theme]);

  useEffect(() => {
    if (theme === ThemeOptions.dark) {
      document.body.classList.add(darkTheme);
    } else {
      document.body.classList.remove(darkTheme);
    }
  }, [theme]);

  return (
    <div className={themeClassName}>
      <OjaUI.Provider
        value={{
          setTheme: (item: ThemeOptions) => setTheme(item),
          theme,
        }}
      >
        <ModalProvider>
          <LayoutContainer singleColumn={layoutOptions?.hideSideMenu}>
            {!layoutOptions?.hideSideMenu && (
              <MobileNav
                handleNavToggle={() => {
                  setShowMobileSideMenu(!showMobileSideMenu);
                }}
              />
            )}

            {!layoutOptions?.hideSideMenu && (
              <Navigation
                {...navProps}
                showMobileSideMenu={showMobileSideMenu}
                setCurrentStore={navProps.setCurrentStore}
                setShowMobileSideMenu={setShowMobileSideMenu}
              />
            )}

            <Main
              css={{
                ...(layoutOptions?.mainContainerStyles || {}),
              }}
            >
              {!layoutOptions?.hideTopNav && (
                <TopNavigation
                  currentPagePath={currentPagePath}
                  items={currentSubNavItems}
                />
              )}

              {children}
            </Main>
          </LayoutContainer>
        </ModalProvider>
      </OjaUI.Provider>
    </div>
  );
};

export const useUI = () => React.useContext(OjaUI);
