import { User } from "../modules/suite/user/user.model";
import {
  AddressDto,
  FulfillmentChannel,
} from "../modules/suite/order/order.model";

export enum FulfilmentChannels {
  PICKUP = "pickup",
  DELIVERY = "delivery",
  IN_STORE = "in-store",
}

export enum FulfillmentChannelCodes {
  Pickup = "2",
  Delivery = "3",
  InStore = "1",
}

export type PickupData = {
  location_id: string;
  date: string;
};

export type DeliveryData = {
  address_id: string;
};

export type InStoreData = {
  location_id: string;
  date: string;
};

export type FulfillmentChannelData = PickupData | DeliveryData | InStoreData;

export interface FulfillmentDto {
  channel_id: string | number; // In store, Pickup, Delivery
  channel_data: FulfillmentChannelData;
  cost?: number;
  addresses: AddressDto[];

  time?: string;
  date?: string;
}

export enum FulfillmentStatus {
  Pending = "pending",
  Processing = "processing",
  Packed = "packed",
  Shipped = "shipped",
  Delivered = "delivered",
  Cancelled = "cancelled",
  DeliveryFailed = "delivery_failed",
  CompletedInStore = "completed_in_store",
}

export const fulfillmentStatusRules = {
  success: [
    FulfillmentStatus.Shipped,
    FulfillmentStatus.Delivered,
    FulfillmentStatus.CompletedInStore,
  ],
  pending: [FulfillmentStatus.Pending, FulfillmentStatus.Processing],
  error: [FulfillmentStatus.Cancelled, FulfillmentStatus.DeliveryFailed],
  warning: [FulfillmentStatus.Pending],
};

export interface FulfillmentHistory {
  id: string;
  action_by: User;
  channel: FulfillmentChannel;
  data: FulfillmentChannelData;
  data_detail: {
    [key: string]: string;
  };
  created_at: string;
  updated_at: string;
  order: string;
  status: string;
}
