import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const config = {
  app: {
    environment: publicRuntimeConfig.app.environment,
    isProduction: publicRuntimeConfig.app.environment === "production",
  },

  apis: {
    baseUrl: publicRuntimeConfig.apis.baseUrl,
  },

  apps: {
    app: {
      url: publicRuntimeConfig.apps.app.url,
    },
  },

  integrations: {
    instagram: {
      clientId: publicRuntimeConfig.integrations.instagram.clientId,
      redirectUri: publicRuntimeConfig.integrations.instagram.redirectUri,
      scope: "user_profile,user_media",
    },

    mui: {
      licenseKey: publicRuntimeConfig.integrations.mui.licenseKey,
    },
  },
};

export default config;
