import { useQuery } from "react-query";
import {
  EleoTimePeriod,
  PriceHistoryStats,
  ProductionStats,
  SmartStockStats,
  StoreInventoryStats,
  StoreOrderRelatedStats,
  StoreSalesPerformance,
  SupplierStats,
} from "./storeReporting.model";
import { StoreReportingService } from "./storeReporting.service";

export const useGetStoreSalesStats = (
  storeId: string,
  period: EleoTimePeriod | string,
) =>
  useQuery<StoreSalesPerformance, Error>(
    `storeSalesStats_${storeId}_${period}`,
    () => {
      const storeReportingService = new StoreReportingService();
      return storeReportingService.getSalesStats(storeId, period);
    },
    {
      enabled: Boolean(storeId && period),
    },
  );

export const useGetStoreInventoryStats = (
  storeId: string,
  period: EleoTimePeriod | string,
) =>
  useQuery<StoreInventoryStats, Error>(
    ["storeInventoryStats", storeId, period],
    () => {
      const storeReportingService = new StoreReportingService();
      return storeReportingService.getStoreInventoryStats(storeId, period);
    },
    {
      enabled: Boolean(storeId),
    },
  );
export const useGetStoreProductionStats = (
  storeId: string,
  period: EleoTimePeriod | string,
) =>
  useQuery<ProductionStats, Error>(
    ["storeProductionStats", storeId, period],
    () => {
      const storeReportingService = new StoreReportingService();
      return storeReportingService.getStoreProductionStats(storeId, period);
    },
    {
      enabled: Boolean(storeId),
    },
  );
export const useGetStoreSmartStockStats = (
  storeId: string,
  period: EleoTimePeriod | string,
) =>
  useQuery<SmartStockStats, Error>(
    ["storeSmartStockStats", storeId, period],
    () => {
      const storeReportingService = new StoreReportingService();
      return storeReportingService.getStoreSmartStockStats(storeId, period);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetStorePriceHistoryStats = (
  storeId: string,
  period: EleoTimePeriod | string,
) =>
  useQuery<PriceHistoryStats, Error>(
    ["storePriceHistoryStats", storeId, period],
    () => {
      const storeReportingService = new StoreReportingService();
      return storeReportingService.getStorePriceHistoryStats(storeId, period);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetStoreOrderRelatedStats = (
  storeId: string,
  period: EleoTimePeriod | string,
  limit?: string,
) =>
  useQuery<StoreOrderRelatedStats, Error>(
    ["storeOrderRelatedStats", storeId, period, limit],
    () => {
      const storeReportingService = new StoreReportingService();
      return storeReportingService.getStoreOrderRelatedStats(
        storeId,
        period,
        limit,
      );
    },
    {
      enabled: Boolean(storeId),
    },
  );
export const useGetSupplierStats = (
  storeId: string,
  supplierId: string,
  period: EleoTimePeriod | string,
) =>
  useQuery<SupplierStats, Error>(
    `storeSupplierStats_${storeId}_supplier_${supplierId}_${period}`,
    () => {
      const storeReportingService = new StoreReportingService();
      return storeReportingService.getSupplierStats(
        storeId,
        supplierId,
        period,
      );
    },
    {
      enabled: Boolean(storeId && supplierId),
    },
  );
