import { PublicMediaItem } from "../../../models/media.model";

export enum EleoTimePeriod {
  Today = "today",
  Yesterday = "yesterday",
  Last7Days = "last_7_days",
  Last30Days = "last_30_days",
  ThisMonth = "this_month",
  LastMonth = "last_month",
  ThisYear = "this_year",
  LastYear = "last_year",
  AllTime = "all_time",
  Range = "range_",
}

export interface ProductSalesPerformanceItem {
  id: number;
  slug: string;
  name: string;
  product_type: string;
  headline_image: PublicMediaItem;
  total_amount: number;
  total_orders: number;
}

export interface CategorySalesPerformanceItem {
  category_id: number;
  slug: string;
  name: string;
  total_amount: number;
  order_count: number;
  units_sold: number;
}

export interface SalesPeriod {
  total_amount: number;
  total_orders: number;
  top_products: ProductSalesPerformanceItem[];
  period_title: string;
  top_categories: CategorySalesPerformanceItem[];
}

export interface StoreSalesPerformance {
  current_period: SalesPeriod;
  previous_period?: SalesPeriod;
}

export interface StoreInventoryStatProduct {
  id: number;
  slug: string;
  name: string;
  product_type: string;
  price: number;
  product_value: number;
}

export type StoreInventoryStats = {
  inventory_value: number;
  average_price: number;
  in_stock_count: number;

  num_products: number;
  num_bundles: number;

  out_of_stock_products: number;
  out_of_stock_bundles: number;

  valuable_products: StoreInventoryStatProduct[];

  recent_products: StoreInventoryStatProduct[];
};

export type GenericStatItem = {
  item_id: string;
  name: string;
  count: number;
};

export type StoreOrderRelatedStats = {
  by_payment_methods: GenericStatItem[];
  by_outlets: GenericStatItem[];
  by_fulfillment_channels: GenericStatItem[];
  by_created_by: GenericStatItem[];
};

export type SupplierStats = {
  inbound_stock: {
    total_pending: number;
    total_amount: number;
  };
  period_title: string;
};

export type ProductionStats = {
  production: {
    produced_units: number;
    total_input_cost: number;
    total_output_value: number;
    total_production: number;
  };
  profitability: {
    average_profit_percent: number;
    total_profit: number;
  };
  period_title: string;
};

export type SmartStockStats = {
  smartstock: {
    total_suppliers: number;
    total_smartstocks: number;
    total_smartstock_value: number;
    total_logistic_fees: number;
    total_outstanding_payment: number;
  };
  period_title: string;
};

export type PriceHistoryStats = {
  price_history: {
    created_by: GenericStatItem[];
  };
  period_title: string;
};
