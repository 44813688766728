import { BaseService } from "../../../services/base.service";
import {
  EleoTimePeriod,
  PriceHistoryStats,
  ProductionStats,
  SmartStockStats,
  StoreInventoryStats,
  StoreOrderRelatedStats,
  StoreSalesPerformance,
  SupplierStats,
} from "./storeReporting.model";
import { ApiClientResponse } from "../../../models/responses";

export class StoreReportingService extends BaseService {
  getSalesStats(
    storeId: string,
    period: EleoTimePeriod | string,
  ): Promise<StoreSalesPerformance> {
    const reqUrl = `/store-stats/${storeId}/sales/`;
    const req = this.apiClient.get<ApiClientResponse<StoreSalesPerformance>>(
      reqUrl,
      {
        params: {
          period,
        },
      },
    );
    return this.makeRequest<StoreSalesPerformance>(req, reqUrl);
  }

  getStoreInventoryStats(
    storeId: string,
    period: EleoTimePeriod | string,
  ): Promise<StoreInventoryStats> {
    const reqUrl = `/store-stats/${storeId}/inventory/`;
    const req = this.apiClient.get<ApiClientResponse<StoreInventoryStats>>(
      reqUrl,
      {
        params: {
          period,
        },
      },
    );
    return this.makeRequest<StoreInventoryStats>(req, reqUrl);
  }

  getStoreProductionStats(
    storeId: string,
    period: EleoTimePeriod | string,
  ): Promise<ProductionStats> {
    const reqUrl = `/store-stats/${storeId}/production/`;
    const req = this.apiClient.get<ApiClientResponse<ProductionStats>>(reqUrl, {
      params: {
        period,
      },
    });
    return this.makeRequest<ProductionStats>(req, reqUrl);
  }

  getStoreSmartStockStats(
    storeId: string,
    period: EleoTimePeriod | string,
  ): Promise<SmartStockStats> {
    const reqUrl = `/store-stats/${storeId}/smart-stock/`;
    const req = this.apiClient.get<ApiClientResponse<SmartStockStats>>(reqUrl, {
      params: {
        period,
      },
    });
    return this.makeRequest<SmartStockStats>(req, reqUrl);
  }

  getStorePriceHistoryStats(
    storeId: string,
    period: EleoTimePeriod | string,
  ): Promise<PriceHistoryStats> {
    const reqUrl = `/store-stats/${storeId}/price-history/`;
    const req = this.apiClient.get<ApiClientResponse<PriceHistoryStats>>(
      reqUrl,
      {
        params: {
          period,
        },
      },
    );
    return this.makeRequest<PriceHistoryStats>(req, reqUrl);
  }

  getStoreOrderRelatedStats(
    storeId: string,
    period: EleoTimePeriod | string,
    limit?: string,
  ): Promise<StoreOrderRelatedStats> {
    const reqUrl = `/store-stats/${storeId}/orders/related/`;
    const req = this.apiClient.get<ApiClientResponse<StoreOrderRelatedStats>>(
      reqUrl,
      {
        params: {
          period,
          limit,
        },
      },
    );
    return this.makeRequest<StoreOrderRelatedStats>(req, reqUrl);
  }

  getSupplierStats(
    storeId: string,
    supplierId: string,
    period: EleoTimePeriod | string,
  ): Promise<SupplierStats> {
    const reqUrl = `/store-stats/${storeId}/suppliers/${supplierId}/`;
    const req = this.apiClient.get<ApiClientResponse<SupplierStats>>(reqUrl, {
      params: {
        period,
      },
    });

    return this.makeRequest<SupplierStats>(req, reqUrl);
  }
}
